






































import Vue from "vue";
import {ToggleButton} from "vue-js-toggle-button";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "select-genre-modal" as string,
  data() {
    return {
      included: this.isGenreSelected,
      i18: i18n
    }
  },
  components: {
    ToggleButton
  },

  props: {
    data: Object,
    isGenreSelected: Boolean
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if("select-genres-modal" === modalId){
        this.included = this.isGenreSelected
      }
    })
},

  methods: {
    checkboxChanged(value: boolean) {
      this.included = value
      this.$emit("emitSelectedGenre", value, this.data);
    },
    setEnergy(energy) {
      if(energy == 1){
        return this.$t('Low')
      }
      if(energy == 2){
        return this.$t('Low to medium')
      }
      if(energy == 3){
        return this.$t('Low to high')
      }
      if(energy == 4){
        return this.$t('Medium')
      }
      if(energy == 5){
        return this.$t('High')
      }
    }
  },
  watch: {
    isGenreSelected: {
      async handler(value: boolean): Promise<void> {
        this.included = value
      },
      deep: true
    }
  }
})

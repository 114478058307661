















































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import SelectGenreModal from "@/components/modals/SelectGenreModal.vue";
import SelectMoreGenres from "@/components/modals/SelectMoreGenres.vue";
import BackButton from "@/components/back-button.vue";
import {mapActions, mapGetters} from "vuex";
import {GenresData} from "@/types";
import {successMessage} from "@/utils/messages-handlers";

export default Vue.extend({
  name: "select-genres" as string,

  components: {
    Layout,
    "select-genre-modal": SelectGenreModal,
    "select-more-genres": SelectMoreGenres,
    "back-button": BackButton
  },

  data() {
    return {
      title: "Get creative. Follow your taste and compile your own playlist",
      modalTitleActive: true,
      modalTitleText: 'Included',
      selectedGenre: {} as GenresData,
      isGenreSelected: false,
      selectedGenres: [] as Array<GenresData>,
      censoredGenres: [],
      disabled:false
    }
  },

  async mounted(): Promise<void> {
    await this.setGenres();
    this.fetchGenresImages();
    await this.fetchCustomPlaylist({venue_id: localStorage.getItem("venue_id")});
    await this.setVenue({venue_id: localStorage.getItem("venue_id")});
    if(this.customPlaylist.censor_genre_ids){
      this.selectedGenres = this.genres.filter(({id}) => !this.customPlaylist.censor_genre_ids.includes(id));
      this.censoredGenres = [...this.customPlaylist.censor_genre_ids]
    }else{
      this.selectedGenres = this.genres
    }
    if(!this.customPlaylist.created){
      this.selectedGenres = this.genres.filter(genre => genre.custom_playlist_default == true);
      this.genres.filter(genres => genres.custom_playlist_default == false).map(genre =>{
      this.censoredGenres.push(genre.id)
    })
    }
  },

  computed: {
    ...mapGetters("playlistModule", {
      genres: "GET_GENRES",
      customPlaylist: "GET_CUSTOM_PLAYLIST",
      venue: "GET_SELECTED_VENUE",
    })
  },

  methods: {
    ...mapActions("playlistModule", {
      setGenres: "FETCH_GENRES",
      setCustomPlaylist: "SET_CUSTOM_PLAYLIST",
      fetchCustomPlaylist: "FETCH_CUSTOM_PLAYLIST",
      setVenue: "FETCH_VENUE",
      updateVenueData: "UPDATE_VENUE",
    }),
    ...mapActions("globalModule", {
      setImage: "FETCH_ENTITY_IMAGE"
    }),
    insertGenreImage(value: string): string {
      if (!value) return "";
      return `data:image/jpeg;charset=utf-8;base64, ${value}`
    },
    fetchGenresImages(): void {
      for (let i = 0; i < this.genres.length; i++) {
        const genre: GenresData = this.genres[i];

        if (!!genre.image_last_updated) {
          this.setImage({id: genre.id, imageType: "genre", multi: true});
        }
      }
    },
    openGenreModal(value: GenresData): void {
      this.selectedGenre = value;
      this.isGenreSelected = !!this.selectedGenres.find(g => g.id === value.id);
      this.$bvModal.show("select-genres-modal");
    },
    addGenreToSelectedList(value: GenresData): void {
      this.selectedGenres.push(value);
      this.censoredGenres = this.censoredGenres.filter(g => g !== value.id);
    },
    removeGenreFromSelectedList(value: GenresData): void {
      this.selectedGenres = this.selectedGenres.filter(g => g.id !== value.id);
      this.censoredGenres.push(value.id);
    },
    checkSelectedGenres(id: number): boolean {
      return this.selectedGenres.find(g => g.id === id);
    },
    emitSelectedGenre(state: boolean, value: GenresData): void {
      if (state) this.addGenreToSelectedList(value);
      else this.removeGenreFromSelectedList(value);
    },
    async GenerateCustomPlaylist() {
      this.disabled = true;
      let payload = await this.censoredGenres;
      const venuePayloadAliasID = {};
      try {
        await this.setCustomPlaylist({
          censor_genre_ids: payload
        });
        await this.updateVenueData(venuePayloadAliasID);
        this.$router.push("/select-playlist");
        successMessage('Playlist changed', 3000);
        this.disabled = false;
      } catch (e) {
        if (this.customPlaylist === "CustomPlaylistNotEnoughSongsError") {
          this.$bvModal.show("select-more-genres");
          this.disabled = false;
        }
      }
    },
  }
})






























import Vue from "vue";

export default Vue.extend({
  name: "select-more-genres" as string,

})
